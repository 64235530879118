@import "bootstrap-icons";

/*!
 *  @author Codifica Krzysztof Samełko <info@codifica.pl>
 *  @link www.codifica.pl
 *  @copyright (c) 2023, Codifica Krzysztof Samełko
 */

/** ========== Główne ========== **/

html {
    font-size: 16px;
}

body {
    font-family: 'Raleway', sans-serif;
    background-color: #ffffff;
}

a {
    color: #e5403a;
    text-decoration: none;
    transition:all 0.3s ease-in-out;
}

a:hover {
    color:#000;

}


/** ========== Przyciski ========== **/
.btn {
    transition: all 0.3s ease-in-out;
    outline: none;
}

.btn-primary {
    border-radius: 0;
    color: #fff !important;
    background-color:#e5403a;
    border-color:#e5403a;
    font-size: 1.125rem; /*18px*/
    font-weight: 700;
    padding: 8px 35px;
}

.btn-primary:hover {
    background-color:#000;
    border-color:#000;
}

.btn-primary i {
    transition: all 0.3s ease-in-out;
}

.btn-primary:hover i {
    transform: rotate(180deg);
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    background-color:#e5403a;
    border-color:#e5403a;
}

/** ========== Formularze ========== **/
.form-check-input:checked {
    background-color: #e5403a;
    border-color: #e5403a;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem #edd1d1;
    border-color: #e5403a;
}

.form-check label {
    cursor: pointer;
}

.form-control {
    border-radius: 0;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem #edd1d1;
    border-color: #e5403a;
}

/** ========== Nagłówek ========== **/
.main-header {
    padding: 20px 0;
    border-bottom: 1px solid #e4e4e4;
}

.main-header .main-logo {
    width: 250px;
}

.main-header .main-logo img {
    width: 100%;
}


/** Stopka **/
.main-footer {
    background-color: #212121;
    color: #6d6d6d;
    padding: 50px 0 0 0;
    text-align: center;
}

.main-footer .footer-menu {
    padding: 40px 0;
}

.main-footer .footer-menu ul {
    padding: 0;
    margin: 0;
}

.main-footer .footer-menu ul li {
    list-style: none;
    display: inline-block;
}

.main-footer .footer-menu ul li a {
    color: #fff;
    font-size: 0.875rem;
    padding: 0 8px;
    text-decoration: none;
}

.main-footer .bottom-footer {
    background-color: #1d1d1d;
    padding: 40px 0 40px 0;
    font-size: 0.875rem;
}

.main-footer .bottom-footer a {
    color: #6d6d6d;
}

.main-footer .bottom-footer a:hover {
    color: #fff;
}


/** Główny kontener **/
#main {
    padding: 50px 0;
}

#main h1.title {
    color: #000;
    font-weight: 700;
    margin: 0;
    /*font-size: 3.125rem;*/
    font-size: 2.5rem; /** 40px; **/
}

.page #main h1.title {
    padding-bottom: 50px;
}

#main h3 {
    font-weight: 700;
}

#main .content {

}

label.error {
    color: red;
    font-size: 14px;
}

/** ========== Cookies ========== **/
.cookies {
    position: fixed;
    bottom: 0;
    display: block;
    background: #e5e5e5;
    width: 100%;
    padding: 10px;
    text-align: center;
    z-index: 100;
}

.cookies .close {
    font-weight: bold;
    color: #000;
    float: none;
    opacity: 1;
    font-size: 1em;
}


/** Generator haseł **/
.password-generator-container.container {
    max-width: 728px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.password-generator-container .password {
    width: 100%;
    border: 1px solid #e4e4e4;
    padding: 45px 0;
    text-align: center;
    font-size: 35px;
}

.password-generator-container .password #password {
    border: none;
    text-align: center;
    width: 100%;
}

.password-generator-container .password #password:focus-visible,
.password-generator-container .password #password:focus {
    border: none;
    outline: none;
}

.password-strength {

}

.password-length .form-control {
    width: 100px;
}

.password-info p {
    background-color: #f5f5f5;
    padding: 10px;
}
